<template>
  <div class="pa-2">
    <v-card>
      <v-card-text :style="{ height: contentHeight + 'px' }">
        <a-table-f-api
          ref="table"
          :api="url"
          :model="model"
          :useQuery="true"
          :defaults="{}"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import api from "../config/api";
export default {
  data() {
    return {
      contentHeight: 0,
      url: api.baseUrl + "caller/promo",
      model: [
        { name: "code", title: "Промокод", type: "string", width: 200 },
        {
          name: "payments",
          title: "Телефон",
          type: "datajson",
          field: "phone",
          sortable: true,
          width: 200,
        },
        {
          name: "payments",
          title: "Клиент ID",
          type: "datajson",
          field: "userid",
          width: 200,
        },
        {
          name: "sms_date",
          title: "Дата отправки",
          type: "datetime",
          sortable: true,
          width: 200,
        },
        {
          name: "payments",
          title: "Дата оплаты",
          type: "datajson",
          field: "acceptdate",
          width: 200,
        },
        {
          name: "is_payed",
          title: "В архив",
          type: "promocheck",
          width: 200,
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize, true);
    this.onResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize, true);
  },
  methods: {
    onResize() {
      let height = window.innerHeight;
      this.contentHeight = height - 76;
    },
  },
};
</script>